import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Buefy
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import '@/assets/css/variables.sass'
import '@/assets/css/main.sass'
import '@/assets/css/animations.sass'
Vue.use(Buefy)

// Vue Particle
import VueParticles from 'vue-particles'
Vue.use(VueParticles)
        
// Font awesome icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faHome, faAd, faForward, faPlane, faClipboard, faCreditCard, faCheckCircle, faMousePointer, faEye, faLaptopHouse,
faPencilRuler, faBriefcase, faCopy, faFolderOpen, faHeartbeat, faSearchLocation, 
faUser, faLandmark, faShoppingCart, faTrash, faAlignJustify, faArrowLeft, faEnvelope, faEdit, faArrowRight, faSearch, faPowerOff, 
faAddressBook, faExclamationTriangle, faFileInvoiceDollar, faBriefcaseMedical, faCar, faSun, faUserShield, faUserFriends, faShip,
faPaw, faSnowflake, faFire, faFireExtinguisher, faIdBadge, faUserLock, faTruck, faMobileAlt, faMapMarkerAlt, faHardHat, faKey, 
faRulerCombined, faHouseUser, faHammer, faTools, faToilet, faTint, faStar, faVolumeMute, faLightbulb, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add( faUserSecret, faHome, faAd, faForward, faPlane, faClipboard, faCreditCard, faCheckCircle, faMousePointer, faEye, faLaptopHouse,
faPencilRuler, faBriefcase, faCopy, faFolderOpen, faHeartbeat, faSearchLocation, faShoppingCart, 
faUser, faLandmark, faTrash, faAlignJustify, faArrowLeft, faEnvelope, faEdit, faArrowRight, faSearch, 
faPowerOff, faAddressBook, faExclamationTriangle, faFileInvoiceDollar, faBriefcaseMedical, faCar, faSun, faUserShield, faUserFriends, faShip,
faPaw, faSnowflake, faFire, faFireExtinguisher, faIdBadge, faUserLock, faTruck, faMobileAlt, faMapMarkerAlt, faHardHat, faKey, 
faRulerCombined, faHouseUser, faHammer, faTools, faToilet, faTint, faStar, faVolumeMute, faLightbulb, faClock )
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Meta-data
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
