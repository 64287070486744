export default {
    namespaced: true,
    state: {

        //Marca
        brand:{
            name:'Compramos Juguetes Usados',
            slogan:'He-Man, My Little Pony, Silverhawks, Thundercats, Star Wars, Superamigos, Figuritas, Videojuegos y Mask',
            email: 'info@comprojuguetesusados.com.ar', // Para el servidor usar de email usar el password: 3610410543aA$q 
            emailUrl: 'comprojuguetesusados.com.ar',
            phone: '+54 11 23942241',

            urlWebForImgEmail: 'https://comprojuguetesusados.com.ar',
            whatsappUrl: 'https://wa.me/+541123942241?text=Hola! Tengo un juguete que me interesa vender, esta es la fotografía:',
            urlIG:'',
            urlFB:''
        },

    },

    mutations: {

    },

    actions: {

    },
    getters:{
        
    }
}