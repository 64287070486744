<template>
  <div class="container-fluid paddingServiceSection pt-2 bgColorSoftGrey" :class="classContainer">
    <br><br><br><br>
    <div class="container">
      <h1
        class="fontTertiary is-size-1 has-text-dark">
        {{title}}
      </h1>
      <div class=" lineFooter2 bgColorGrey is-block mx-auto mt-4" />
      <div class=" columns is-gapless p-5 is-multiline">
        <div class="column is-mobile-12 mt-6 is-4">
            <img class="imgBox icons2" src="../../assets/img/icons/solicitud-de-cotizacion.png"> 
            <p class=" mt-5 mb-0 is-size-4-tablet is-size-5-mobile">
             La mejor cotización que vas a conseguir
            </p>
        </div>
        <div class="column is-mobile-12 mt-6 is-4">
            <img class="imgBox icons2" src="../../assets/img/icons/money-bag.png"> 
            <p class=" mt-5 mb-0 is-size-4-tablet is-size-5-mobile">
             Abonamos en el acto para que recibas el dinero
            </p>
        </div>
        <div class="column is-mobile-12 mt-6 is-4">
            <img class="imgBox icons2" src="../../assets/img/icons/dinero.png"> 
            <p class=" mt-5 mb-0 is-size-4-tablet is-size-5-mobile">
             Pagamos en efectivo o por transferencia
            </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'propImg1', 'propName1', 'propImg2', 'propName2', 'propImg3', 'propName3',
  'propName4', 'propImg4', 'propName5', 'propImg5', 'propName6', 'propImg6', 'classContainer'],
  data() {
    return {
      services: [
       {
        img: this.propImg1,
        name: this.propName1,
        number: 4
       },
       {
        img: this.propImg2,
        name: this.propName2,
        number: 4
       },
       {
        img: this.propImg3,
        name: this.propName3,
        number: 4
       },
       {
        img: this.propImg4,
        name: this.propName4,
        number: 4
       },
       {
        img: this.propImg5,
        name: this.propName5,
        number: 4
       },
       {
        img: this.propImg6,
        name: this.propName6,
        number: 4
       }          
      ]
    }
  }
}
</script>

<style>

</style>
