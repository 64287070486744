<template>
  <section>
    <a @click="setWhatsapp" target="_blank" class="whatsapp" @mouseover="animateWhatsappImg">
      <img src="@/assets/img/whatsapp.webp" :class="animateImg">
      <p class="requestBudget fontWeightRegular">
       <font-awesome-icon icon="arrow-left" class="iconWhatsappBudgeter" />
        Enviar foto para cotizar
      </p>
    </a>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Whatsapp',
  data () {
    return {
      wpTime: 6
    }
  },
  computed: {
    ...mapState('brand', ['brand'])
  },
  methods: {
    setWhatsapp () {
      this.$buefy.dialog.alert({
          title: 'IMPORTANTE!',
          message: `<p syle="text-align: center">
          <span class="fontWeightBlack">Al comunicarte por Whatsapp envianos la FOTO DE TU JUGUETE</span>. <br> <br> Serás redirigo a Whatsapp en 3 segundos.
          </p>`,
          confirmText: 'Whatsapp'
      })
      this.wpTime = 3
      setTimeout(() => {
        window.location.href=this.brand.whatsappUrl
      }, 3000)
    }
  }
}
</script>
