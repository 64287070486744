<template>
  <div class="home">
    <!-- Slider -->
    <Slider  id="inicio"/>
    <Services id="services"
    :title="'El mejor servicio de compra'" 
    :propName1="'La mejor cotización que vas a conseguir'"
    :propName2="'Abonamos en el acto para que recibas el dinero'"
    :propName3="'Pagamos en efectivo o por transferencia'"
    :propImg1="'solicitud-de-cotizacion'"
    :propImg2="'money-bag'"
    :propImg3="'dinero'"
    :classContainer="''"
    />
    <br><br>
    <div class="container">
    <div class="columns is-multiline pb-5 mb-5">
      <div class="column is-12 blockCenter mt-5">
      <h1
          class="fontItalic is-size-4 has-text-dark has-text-left mb-5">
        ¿Tenes juguetes viejos acumulados? Revisalos!
      </h1>
        <div class="columns is-multiline">
          <div class="blockCenter mb-5" style="border: 1px dashed #333; opacity: 0.4;width: 98%"></div>
          <div class="column is-3">
              <img class="imgBox" src="@/assets/img/juguetes/hemanVert1.jpg"> 
          </div>
          <div class="column is-3">
              <img class="imgBox" src="@/assets/img/juguetes/thundercatsVert1.jpg"> 
          </div>
          <div class="column is-3">
              <img class="imgBox" src="@/assets/img/juguetes/figuritasVert1.jpeg"> 
          </div>
          <div class="column is-3">
              <img class="imgBox" src="@/assets/img/juguetes/consolasVert1.jpg"> 
          </div>
          <div class="blockCenter mt-5" style="border: 1px dashed #333; opacity: 0.4;width: 98%"></div>
        </div>
      </div>
    </div>
    </div>
    <br><br>
    <!-- <SeparatorSection
    :titleOne="'NOS ESPECIALIZAMOS EN'" 
    :titleOneColor="'has-text-white fontWeightBlack'"
    :titleTwo="'JUGUETES DE LOS 80s y 90s'"
    :titleTwoColor="'colorPrimary fontWeightBlack'"
    :titleThree="''"
    :titleThreeColor="'has-text-white fontWeightBlack'"
    :subtitleOne="'Compramos rápido y al mejor precio que podes conseguir'"
    :subtitleTwo="'Contactanos | Presupuestos en 48 HS'"
    :subtitleColor="'has-text-white'"
    :bgSeparator="'bgColorBlack p-6'"
    />
    <img src="../assets/img/juguetes/fondo3.jpg" class="width100 is-block"> -->
    <div id="juguetes" class="bgColorBlack paddingBlock">
      <div class="container">
        <h1
          class="is-size-1 has-text-white fontTertiary" style="z-index: 10;position:relative">
          Juguetes, figuritas, videojuegos <br> y consolas que buscamos
        </h1>
        <p class="mt-5 mb-0 is-size-4-tablet is-size-5-mobile has-text-white has-text-centered">
          En el catálogo de abajo vas a poder ver los juguetes que nos interesan
        </p>
     </div>
    </div>
    <Catalog />

    <!-- Footer -->
    <Footer id="contact" />
    <Whatsapp />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Slider from '@/components/sections/Slider'
// import SeparatorSection from '@/components/sections/SeparatorSection'
import Catalog from '@/components/sections/Catalog'
import Services from '@/components/sections/Services'
import Footer from '@/components/Footer'
import Whatsapp from '@/components/Whatsapp'
export default {
  name: 'Home',
  components: {
    Slider ,
    // SeparatorSection,
    Catalog,
    Services,
    Footer,
    Whatsapp
  },
  data () {
    return {
      animateImg: 'none'
    }
  },
  computed: {
    ...mapState('brand', ['brand'])
  },
  methods: {
    animateWhatsappImg () {
      this.animateImg = 'rotateImgOnHover'
      setTimeout(function(){
        this.animateImg = 'none'
      }.bind(this), 1000)
    }
  }
}
</script>
