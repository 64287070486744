<template>
<div>
  <div class="container-fluid paddingServiceSection pt-2" :class="classContainer">
    <br><br><br><br>
    
  <div id="heman" class="container-fluid paddingServiceSection pt-2" :class="classContainer">

    <div class="container">
      <h1
        class=" is-size-2 has-text-dark fontTertiary fontTertiary">
        He-Man
      </h1>
      <div class="blockCenter mt-5" style="border: 1px dashed #333; opacity: 0.4;width: 94%"></div>
      <div class=" columns p-5 is-multiline">
        <div class="column is-12">
          <img class="boxImg2 width100" src="@/assets/img/catalogo/heman/3.jpg"> 
        </div>
        <div class="column is-12">
          <img class="boxImg2 width100" src="@/assets/img/catalogo/heman/4.jpg"> 
        </div>
        <div class="column is-6">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/heman/1.png"> 
        </div>
        <div class="column is-6">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/heman/2.png"> 
        </div>
      </div>
    </div>
    
  </div>
    <br><br>
  <div id="pony" class="container-fluid paddingServiceSection pt-2 bgColorSoftGrey" :class="classContainer">
    <br><br>

    <div class="container">
      <h1
        class=" is-size-2 has-text-dark fontTertiary">
        My Little Pony
      </h1>
      <div class="blockCenter mt-5" style="border: 1px dashed #333; opacity: 0.4;width: 94%"></div>
      <div class=" columns p-5 is-multiline">
        <div class="column is-6">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/ponys/1.png"> 
        </div>
        <div class="column is-6">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/ponys/2.png"> 
        </div>
      </div>
    </div>



    

  <br><br>
  <div id="silverhawks" class="container-fluid  pt-2 bgColorWhite" :class="classContainer">
    <br><br>

    <div class="container">
      <h1
        class=" is-size-2 has-text-dark fontTertiary">
        Silverhawks
      </h1>
      <div class="blockCenter mt-5" style="border: 1px dashed #333; opacity: 0.4;width: 20%"></div>
      <div class=" columns p-5 is-multiline">
        <div class="column is-6 mx-auto is-block">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/silverhwaks/1.png"> 
        </div>
        <div class="column is-6 mx-auto is-block">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/silverhwaks/2.png"> 
        </div>
        <div class="column is-12 mx-auto is-block">
          <img class="boxImg2 width100" src="@/assets/img/catalogo/silverhwaks/2.jpg"> 
        </div>
      </div>
    </div>
    
  </div>




  


    

  <br><br>
  <div id="thundercats" class="container-fluid paddingServiceSection pt-2" :class="classContainer">
    <br><br>

    <div class="container">
      <h1
        class=" is-size-2 has-text-dark fontTertiary">
        Thundercats
      </h1>
      <div class="blockCenter mt-5" style="border: 1px dashed #333; opacity: 0.4;width: 94%"></div>
      <div class=" columns p-5 is-multiline">
        <div class="column is-12">
          <img class="boxImg2 width100" src="@/assets/img/catalogo/thundercats/1.jpg"> 
        </div>
        <div class="column is-12">
          <img class="boxImg2 width100" src="@/assets/img/catalogo/thundercats/2.jpeg"> 
        </div>
      </div>
    </div>
    
  </div>
    <br><br>



    


  <div id="starwars" class="container-fluid paddingServiceSection bgColorWhite pt-2 " :class="classContainer">
    <br><br>

    <div class="container">
      <h1
        class=" is-size-2 has-text-dark fontTertiary">
        Star Wars
      </h1>
      <div class="blockCenter mt-5" style="border: 1px dashed #333; opacity: 0.4;width: 24%"></div>
      <div class=" columns p-5 is-multiline">
        <div class="column is-6 mx-auto is-block">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/starwars/1.png"> 
        </div>
      </div>
    </div>
        <br><br>
  </div>



  <div id="gijoe" class="container-fluid paddingServiceSection pt-2 bgColorSoftGrey" :class="classContainer">
    <br><br>

    <div class="container">
      <h1
        class=" is-size-2 has-text-dark fontTertiary">
        Gi Joe
      </h1>
      <div class="blockCenter mt-5" style="border: 1px dashed #333; opacity: 0.4;width: 24%"></div>
      <div class=" columns p-5 is-multiline">
        <div class="column is-6 mx-auto is-block">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/gijoe/1.png"> 
        </div>
        <div class="column is-12">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/gijoe/3.jpeg"> 
        </div>
      </div>
    </div>
    
  </div>    <br><br>
    
  </div>



  
  <div id="superamigos" class="container-fluid paddingServiceSection pt-2 bgColorWhite" :class="classContainer">
    <br><br>

    <div class="container">
      <h1
        class=" is-size-2 has-text-dark fontTertiary">
        Superamigos
      </h1>
      <div class="blockCenter mt-5" style="border: 1px dashed #333; opacity: 0.4;width: 24%"></div>
      <div class=" columns p-5 is-multiline">
        <div class="column is-12">
          <img class="boxImg2 width100" src="@/assets/img/catalogo/superamigos/3.png"> 
        </div>
        <div class="column is-12">
          <img class="boxImg2 width100" src="@/assets/img/catalogo/superamigos/4.jpeg"> 
        </div>
      </div>
    </div>
    
  </div>
    <br><br>




<div  id="figuritas" class="bgColorSoftGrey">
    <br><br>
    <div class="container">
      <h1
        class=" is-size-2 has-text-dark fontTertiary">
        Figuritas
      </h1>
      <div class="blockCenter mt-5" style="border: 1px dashed #333; opacity: 0.4;width: 94%"></div>
      <div class=" columns p-5 is-multiline">
        <div class="column is-6">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/figuritas/a1.png"> 
        </div>
        <div class="column is-6">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/figuritas/a2.png"> 
        </div>
        <div class="column is-12">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/figuritas/2.jpeg"> 
        </div>
        <div class="column is-4">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/figuritas/a3.png"> 
        </div>
        <div class="column is-4">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/figuritas/a4.png"> 
        </div>
        <div class="column is-4">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/figuritas/a5.png"> 
        </div>
      </div>
    </div>
    
    <br><br>
  </div>
  </div>
  <div id="consolas" class="container-fluid paddingServiceSection bgColorWhite pt-2" :class="classContainer">
    <br><br>

    <div class="container">
      <h1
        class=" is-size-2 has-text-dark fontTertiary">
        Consolas y videojuegos
      </h1>
      <div class="blockCenter mt-5" style="border: 1px dashed #333; opacity: 0.4;width: 94%"></div>
      <div class=" columns p-5 is-multiline">
        <div class="column is-4">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/consolas/1.png"> 
        </div>
        <div class="column is-4">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/consolas/2.png"> 
        </div>
        <div class="column is-4">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/consolas/3.png"> 
        </div>
        <div class="column is-4">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/consolas/4.png"> 
        </div>
        <div class="column is-4">
          <img class="boxImg2 bgPattern width100" src="@/assets/img/catalogo/consolas/5.png"> 
        </div>
      </div>
    </div>
    
  </div>
    

  <br><br>
  <div id="mask" class="container-fluid paddingServiceSection pt-2 bgColorSoftGrey" :class="classContainer">
    <br><br>

    <div class="container">
      <h1
        class=" is-size-2 has-text-dark fontTertiary">
        Mask
      </h1>
      <div class="blockCenter mt-5" style="border: 1px dashed #333; opacity: 0.4;width: 94%"></div>
      <div class=" columns p-5 is-multiline">
        <div class="column is-4">
          <img class="boxImg2 width100" src="@/assets/img/catalogo/mask/1.jpg"> 
        </div>
        <div class="column is-4">
          <img class="boxImg2 width100" src="@/assets/img/catalogo/mask/2.jpg"> 
        </div>
        <div class="column is-4">
          <img class="boxImg2 width100" src="@/assets/img/catalogo/mask/3.jpg"> 
        </div>
      </div>
    </div>
    
  </div>
    <br><br>

    






    

</div>
</template>

<script>
export default {
  props: ['title', 'propImg1', 'propName1', 'propImg2', 'propName2', 'propImg3', 'propName3',
  'propName4', 'propImg4', 'propName5', 'propImg5', 'propName6', 'propImg6', 'classContainer'],
  data() {
    return {
      services: [
       {
        img: this.propImg1,
        name: this.propName1,
        number: 4
       },
       {
        img: this.propImg2,
        name: this.propName2,
        number: 4
       },
       {
        img: this.propImg3,
        name: this.propName3,
        number: 4
       },
       {
        img: this.propImg4,
        name: this.propName4,
        number: 4
       },
       {
        img: this.propImg5,
        name: this.propName5,
        number: 4
       },
       {
        img: this.propImg6,
        name: this.propName6,
        number: 4
       }          
      ]
    }
  }
}
</script>

<style>

</style>
