<template>
  <section>
    <b-carousel :indicator="false" :pause-info="false">
      <b-carousel-item> <!-- bg-img1 -->
        <section :class="`hero sliderHeightPadding is-bold bg-img1`">
            <div class="hero-body container mt-5">

              
              <div class>
                <h2 class="titleBig2 fontTertiary has-text-white mt-7 has-text-centered scale-in-center fontPrimary">
                  ¿Tenes juguetes usados <br> para vender?
                </h2>
                <h2 class="is-size-4-tablet is-size-5-mobile  has-text-white has-text-centered">
                   Buscamos tus juguetes, figuritas, videojuegos <br> y consolas de los 80s y 90s
                </h2>
                <h2 class="is-size-5 mt-5 mb-2 fontItalic has-text-white has-text-centered fade-in">
                  Trabajamos en toda la Argentina
                </h2>
                <div class="separatorLineNew width10 blockCenter mt-5"></div>
                <h2 class="is-size-5 mt-5 mb-2 fontItalic has-text-white has-text-centered fade-in">
                  Mirá los juguetes, figuritas, videojuegos y consolas que buscamos
                </h2>
                <div class="mt-5">
                  <button
                  class="is-inline-block is-size-6 pl-5 pr-5 pt-2 pb-2 buttonSliderWhatsapp fontWeightBold has-background-success pr-0 has-text-white"
                  onclick="window.location.href='#juguetes'">
                    Ver juguetes
                  </button>
                </div>
              </div>

            </div>
        </section>
      </b-carousel-item>
    </b-carousel>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'Slider',
    data () {
      return {
          param: '',
          progress: true,
          progressType: 'is-primary',
          carousels: [
              {
              background: 'img1'
              },
              {
              background: 'img2'
              }
          ]
      }
    },
    created () {
      this.catchParams()
      console.log(this.$route.query)
    },
    computed: {
      ...mapState('brand', ['brand'])
    },
    methods: {
      catchParams () {
        console.log('Aca', this.$route.query)
        this.param = this.$route.query
    }
  }
}
</script>

<style lang="sass">

.bg-img1
    width: 100%
    background-size: cover
    background-repeat: no-repeat
    @media (min-width: 720px)
        background-image: url("../../assets/img/slider1.jpg")
    @media (max-width: 720px)
        background-image: url("../../assets/img/sliderMobil1.jpg")

</style>
