<!-- eslint-disable -->
<template>
  <footer>
    <div class="container-fluid">
      <div class="container pl-6 pr-6">
        <div class="row">
          <div class="columns is-multiline pt-6 pb-6">   

                          <div class="blockCenter">
                <h2 class="is-size-1 fontNoSoBlackFine has-text-dark mt-7 has-text-centered scale-in-center fontPrimary fontTertiary">
                  Buscamos <br> tus juguetes, figuritas, <br> videojuegos y consolas
                </h2>
              <div class="blockCenter mt-5" style="border: 1px dashed #333; opacity: 0.4;width: 24%"></div>
                <div class="separatorLineNew width10 blockCenter mt-5 bgColorBlack"></div>
                <h2 class="is-size-5 mt-2 mb-2 fontItalic has-text-dark has-text-centered fade-in">
                  Comunicate ahora por Whatsapp
                </h2>
                <div class="mt-5">
                  <button
                  class="is-inline-block is-size-4 pl-5 pr-5 pt-2 pb-2 buttonSliderWhatsapp fontWeightBold has-background-success pr-0 has-text-white"
                  @click="setWhatsapp">
                    Enviar foto para cotizar
                  </button>
                </div>
              </div>


          </div>
        </div>
      </div><br><br><br><br><br>
    </div>
    <div class="bgColorBlack">
        <br>
        <p class="has-text-centered subtitle is-5 has-text-white mb-2 mt-6">
          {{ brand.slogan }}
        </p>
        <p class="has-text-centered subtitle is-5 has-text-white mb-2">
          {{ brand.name }}
        </p>
        <p class="has-text-centered subtitle is-7 has-text-white mb-2 mt-7">
          Desarrollado por <a style="color:#FFF!important" href="https://cmlaplata.com.ar">Community Manager La Plata</a>
        </p>
        <br><br>

    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Footer',
  data () {
    return {
      wpTime: 6
    }
  },
  computed: {
    ...mapState('brand', ['brand'])
  },
  methods: {
    setWhatsapp () {
      this.$buefy.dialog.alert({
          title: 'IMPORTANTE!',
          message: `<p syle="text-align: center">
          <span class="fontWeightBlack">Al comunicarte por Whatsapp envianos la FOTO DE TU JUGUETE</span>. <br> <br> Serás redirigo a Whatsapp en 3 segundos.
          </p>`,
          confirmText: 'Whatsapp'
      })
      this.wpTime = 3
      setTimeout(() => {
        window.location.href=this.brand.whatsappUrl
      }, 3000)
    }
  }
}
</script>
